/* Common Client Styles */
@import '~@frontend/common/build/styles/form_wrapper_styles.css';

/* Colors */
:root {
  --primary: #EA7125;
  --accent: #0072CF;
  --warn: rgb(250, 229, 0);
  --error: #E50000;
  --text: rgba(0, 0, 0, 0.87);
  --linkText: #0072CF;
  --darkPrimary: #0059B6;
  --lightGray: #F5F5F4;
  --lightHeaderText: rgba(0, 0, 0, 0.54);
}

/* Media Breakpoints */
/*
  ---
  mobile-width: 600px;
  tablet-width: 768px;
  desktop-width: 1280px;
  ---
  mobile styles will have a media query of:
  @media (max-width: 767px) {
    // Styles here...
  }

  tablet styles will have a media query of:
  @media (max-width: 1279px) {
    // Styles here...
  }

  desktop styles will have a media query of:
  @media (min-width: 1280px) {
    // Styles here...
  }
*/
