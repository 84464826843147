.Navigation_featuresContainer {
  position: absolute;
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
}

.Navigation_featuresChildren {
  margin-top: 70px;
  padding: 0 10px;
  flex: 1 0 auto;

  &.non-prod {
    margin-top: 110px;
  }
}

footer.LegalFooter_footer {
  margin-top: 0;
}

@media print {
  .Navigation_featuresChildren {
    margin-top: 10px;

    &.non-prod {
      margin-top: 10px;
    }
  }
}
