/*
*
* AppRoot Styles
*
*/
* {
  box-sizing: border-box;
}

html, body {
  height: 100%;
  width: 100%;
  padding: 0;
  margin: 0;
}

body {
  font-family:  Roboto, PT Sans, Arial, sans-serif;
  color: #242424;
  font-size: 100%;
  background-color: #F5F5F5;
}

input[type=number]::-webkit-inner-spin-button, 
input[type=number]::-webkit-outer-spin-button { 
  -webkit-appearance: none; 
  margin: 0; 
}

::-ms-clear, ::-ms-reveal {
  display: none;
}

a, a:visited {
  color: var(--accent);
}

a {
  text-decoration: none;
  cursor: pointer;
}

a:hover {
  text-decoration: underline;
}

@media print {
  .hideOnPrint {
    display: none !important;
  }
}
